<template>
  <div class="exam">
    <header-view></header-view>
    <div class="content" v-loading="loading">
      <div class="head">
        <div class="head-title">{{ info.title }}</div>
        <div class="time">剩余: <span>{{ count }}</span></div>
      </div>
      <div class="box">
        <div class="left">
          <div class="problem">
            <div class="title-box">
              {{ tabIndex + 1 }}.{{ question[tabIndex].title }}
            </div>
            <div class="answer">
              <div
                class="item"
                v-for="(item, index) in question[tabIndex].options"
                :class="item.checkbox ? 'item-active' : ''"
                @click="select(item)"
                :key="index"
              >
                {{ item.answer }}{{ item.content }}
              </div>
            </div>
          </div>
          <div class="bottom-btn">
            <div
              class="btn-item-l"
              @click="bindPre"
              :class="{ botton_btn_active: tabIndex == 0 }"
            >
              <img
                v-show="tabIndex == 0"
                src="../../assets/images/next_w.png"
                alt=""
              />
              <img
                v-show="tabIndex != 0"
                src="../../assets/images/next_b.png"
                alt=""
              />
              上一题
            </div>
            <div class="card-btns" @click="submit()">交卷</div>
            <div
              class="btn-item-r"
              @click="bindNext"
              :class="{
                botton_btn_active: question.length - 1 == tabIndex,
              }"
            >
              下一题
              <img
                v-show="question.length - 1 == tabIndex"
                src="../../assets/images/next_w.png"
                alt=""
              />
              <img
                v-show="question.length - 1 != tabIndex"
                src="../../assets/images/next_b.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="right">
          <!-- v-if="topData.mins != 0" -->
          <div class="time">
            剩余: <span>{{ count }}</span>
          </div>
          <div class="card">
            <div class="card-top">
              <div class="top-item">
                <div class="span span1"></div>
                已做
              </div>
              <div class="top-item">
                <div class="span"></div>
                未做
              </div>
            </div>
            <div class="card-c">
              <div class="list">
                <div
                  v-for="(items, indexs) in question"
                  :key="indexs"
                  class="card-item"
                  :class="items.answers == 1 ? 'card-active' : ''"
                  @click="jump(indexs)"
                >
                  {{ indexs + 1 }}
                </div>
              </div>
            </div>
            <div class="card-b">
              <!-- <div class="card-btn" @click="saveClick()">保存进度，下次继续</div> -->
              <div class="card-btns" @click="submit()">交卷</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="成绩报告"
      :visible.sync="centerDialogVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      width="80%"
    >
      <div class="report-box">
        <div class="left">
          <div class="score">
            <div class="score-c">
              <div class="score-txt">试卷得分</div>
              <div class="score-num">
                {{ reportInfo.correctScore }}<span>分</span>
              </div>
            </div>
          </div>
          <div class="time"></div>
        </div>
        <div class="right">
          <div class="right-c">
            <div class="items">总题数</div>
            <div class="items-a">{{ reportInfo.questionCount }}</div>
          </div>
          <div class="right-c">
            <div class="items">正确题数</div>
            <div class="items-a">{{ reportInfo.correctCount }}</div>
          </div>
          <div class="right-c">
            <div class="items">总分</div>
            <div class="items-a">{{ reportInfo.questionScore }}</div>
          </div>
          <div class="right-c">
            <div class="items">正确率</div>
            <div class="items-a">{{ reportInfo.correctRate }}</div>
          </div>
        </div>
        <div class="report-btn">
          <div class="btn" @click="determineGo">返回</div>
        </div>
      </div>
    </el-dialog>
    <footer-view></footer-view>
  </div>
</template>

<script>
import HeaderView from "@/components/HeaderView";
import FooterView from "@/components/FooterView";
import { beginPaper, commitPaper } from "@/api/exam";
export default {
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    return {
      id: this.$route.query.id,
      question: "",
      info: "",
      tabIndex: 0,
      loading: true,
      count: "", //倒计时
      seconds: 0, // 10天的秒数,
      time: "", //计时器,
      centerDialogVisible: false,
      reportInfo: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 选择
    select(item) {
      console.log(item);
      let list = this.question[this.tabIndex].options;
      let lists = this.question[this.tabIndex];
      for (let i in list) {
        if (lists.typeId == 2) {
          if (list[i].answer == item.answer) {
            list[i].checkbox = !list[i].checkbox;
          }
        } else {
          if (list[i].answer == item.answer) {
            list[i].checkbox = !list[i].checkbox;
          } else {
            list[i].checkbox = false;
          }
        }
      }
      for (let i in lists.options) {
        if (lists.options[i].checkbox) {
          lists.answers = 1;
          break;
        } else {
          lists.answers = 0;
        }
      }
    },
    // 天 时 分 秒 格式化函数
    countDown() {
      let h = parseInt((this.seconds / (60 * 60)) % 24);
      h = h < 10 ? "0" + h : h;
      let m = parseInt((this.seconds / 60) % 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(this.seconds % 60);
      s = s < 10 ? "0" + s : s;
      this.count = h + ":" + m + ":" + s;
    },
    // 上一期
    bindPre() {
      if (this.tabIndex > 0) {
        this.tabIndex = this.tabIndex - 1;
      }
    },
    // 下一题
    bindNext() {
      if (this.question.length - 1 > this.tabIndex) {
        this.tabIndex = this.tabIndex + 1;
      }
    },

    //定时器没过1秒参数减1
    Time() {
      this.time = setInterval(() => {
        if (this.seconds == 0) {
          clearInterval(this.time);
          this.$confirm("考试时间超时，未提交考卷，请重新考试！", "考试结束", {
            confirmButtonText: "确定",
            type: "warning",
            showCancelButton: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            showClose: false,
          }).then(() => {
            this.$router.go(-1);
          });
        } else {
          this.seconds -= 1;
          this.countDown();
        }
      }, 1000);
    },
    getList() {
      beginPaper(this.id)
        .then((res) => {
          console.log(res);
          if (res.data.questionsList.length == 0) {
            this.$confirm("暂无试题！", "提示", {
              confirmButtonText: "确定",
              type: "warning",
              showCancelButton: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              showClose: false,
            }).then(() => {
              this.$router.go(-1);
            });
          }
          let arr = res.data.questionsList;
          console.log();
          for (let i in arr) {
            arr[i].answers = 0;
            arr[i].options = JSON.parse(arr[i].options);
            for (let j in arr[i].options) {
              arr[i].options[j].checkbox = false;
            }
          }
          this.question = arr;
          this.info = res.data;
          console.log(this.question);
          this.seconds = res.data.paperTime * 60;
          this.Time();
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.$router.go(-1);
        });
    },
    // 点击切换题目
    jump(index) {
      if (this.tabIndex != index) {
        this.tabIndex = index;
      }
    },
    determineGo() {
      this.$router.go(-1);
    },
    // 交卷
    submit() {
      let list = this.question;
      let commitList = [];
      for (let i in list) {
        if (list[i].answers == 0) {
          this.$confirm("您有试题未作答,请前往作答！", "提示", {
            confirmButtonText: "确定",
            type: "warning",
            showCancelButton: false,
          }).then(() => {});
          return;
        } else {
          let txt = [], a = "", arr= {}
          for (let j in list[i].options) {
            if (list[i].typeId == 2) {
              if (list[i].options[j].checkbox) {
                txt = txt + list[i].options[j].answer;
              }
            } else {
              if (list[i].options[j].checkbox) {
                a = list[i].options[j].answer;
              }
            }
          }
          if (list[i].typeId == 2) {
              arr.qid=list[i].id
              arr.answer= txt
          }else{
             arr.qid=list[i].id
              arr.answer= a
          }

          commitList.push(arr);
        }
      }
      this.$confirm("提交考卷后，无法修改，是否交卷？", "提示", {
        confirmButtonText: "确定",
        type: "warning",
      }).then(() => {
        commitPaper({
          commitList: commitList,
        })
          .then((res) => {
            clearInterval(this.time);
            this.centerDialogVisible = true;
            this.reportInfo = res.data;
            console.log(res);
          })
          .catch(() => {});
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px){
  .content {
  width: 100% !important;
  }
  .right{
    display: none;
  }
  .content .box .left .bottom-btn{
    padding:0 20px !important;
  }
  .content .box .left .problem{
    overflow-y: auto;
  }
  .content .box .left .problem .answer{
    display: block !important;
  }
  .content .box .left .problem .answer .item{
    width: 100% !important;
  }
  .content .head .time{
    display: flex !important;
  }
  .content .box .left .bottom-btn .card-btns{
     display: flex !important;
  }
  .report-box .right{
    flex-wrap: wrap !important;
    width: 100% !important;
  }

}
.botton_btn_active {
  color: #999999 !important;
  cursor: not-allowed;
}
.exam {
  background: #f9f9f9;
}
.content {
  width: 1170px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  box-sizing: border-box;
  min-height: calc(100vh - 100px);

  .head {
    padding: 30px;
    background: #fff;
    border: 1px solid #eee;
    box-sizing: border-box;
        display: flex;
    flex-direction: column;
    align-items: center;
    .head-title {
      font-size: 21px;
      font-weight: 500;
      color: #333;
      display: flex;
      justify-content: center;
    }
    .time {
        color: #292c35;
        font-size: 16px;
        align-items: center;
        background: #ffffff;
        padding: 0 30px;
        box-sizing: border-box;
        margin-top: 10px;
        display: none;
        span {
          color: #1b5ee7;
        }
      }
  }
  .box {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;

    .left {
      width: 867px;
      margin-top: 2px;
      // overflow-y: scroll;

      .problem {
        background-color: #fff;
        box-sizing: border-box;
        height: calc(100vh - 300px);
        padding: 20px 40px;
        box-sizing: border-box;

        .title-box {
          font-size: 18px;
          color: #292c35;
          flex-shrink: 0;
          margin-bottom: 30px;
        }
        .answer {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .item {
            width: 40%;
            height: 43px;
            background: #fff;
            border: 1px solid hsla(0, 0%, 86.3%, 0.6);
            text-align: center;
            border-radius: 200px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            margin-bottom: 20px;
            cursor: pointer;
          }
          .item-active {
            border: 1px solid #ff7200;
            color: #ff7200;
          }
        }
      }
      .bottom-btn {
        height: 50px;
        width: 100%;
        background: #ffffff;
        padding: 0 101px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        .btn-item-l {
          font-size: 20px;
          color: #333333;
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            width: 9px;
            height: 18px;
            margin-right: 10px;
          }
        }
        .card-btns {
          cursor: pointer;
          width: 35%;
          height: 42px;
          background: #1b5ee7;
          border-radius: 6px;
          font-size: 14px;
          color: #ffffff;
          display: none;
          align-items: center;
          justify-content: center;
        }
        .btn-item-r {
          font-size: 20px;
          color: #333333;
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            width: 9px;
            height: 18px;
            margin-left: 10px;
            transform: rotate(180deg);
          }
        }
      }
    }

    .right {
      // width: 21%;
      width: 280px;
      flex-shrink: 0;

      .time {
        color: #292c35;
        font-size: 16px;
        display: flex;
        align-items: center;
        height: 64px;
        background: #ffffff;
        padding: 0 30px;
        box-sizing: border-box;
        margin-top: 2px;
        margin-bottom: 14px;

        span {
          color: #1b5ee7;
        }
      }

      .card {
        .card-top {
          height: 50px;
          background: #ffffff;
          padding-left: 30px;
          box-sizing: border-box;
          display: flex;
          align-items: center;

          .top-item {
            font-size: 14px;
            color: #292c35;
            display: flex;
            align-items: center;
            margin-right: 20px;

            .span {
              width: 14px;
              height: 14px;
              margin-right: 10px;
              background: #ffffff;
              border: 1px solid #d4d4d4;
              border-radius: 2px;
            }

            .span1 {
              border: 1px solid #1b5ee7;
            }
          }
        }
      }

      .card-c {
        height: 249px;
        background: #fffefe;
        border: 1px solid #eeeeee;
        border-top: 0;
        overflow-y: scroll;
        box-sizing: border-box;
        padding: 0 30px;

        .card-title {
          height: 14px;
          color: #292c35;
          font-size: 14px;
          border-left: 3px solid #1b5ee7;
          padding-left: 11px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          margin: 20px 0;
          span {
            color: #6c7284;
          }
        }
        .list {
          display: flex;
          flex-wrap: wrap;
          margin-top: 20px;

          .card-item {
            width: 36px;
            height: 24px;
            background: #fffefe;
            border: 1px solid #d4d4d4;
            border-radius: 2px;
            font-size: 12px;
            color: #292c35;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 12px;
            margin-right: 12px;
            box-sizing: border-box;
            cursor: pointer;
            &:nth-child(6n) {
              margin-right: 0;
            }
          }

          .card-active {
            border: 1px solid #1b5ee7;
          }
          .card-correct {
            background: #36b257;
            color: #ffffff;
          }
          .card-error {
            background: #f05b47;
            color: #ffffff;
          }
        }
      }

      .card-b {
        // height: 122px;
        width: 100%;
        background: #fffefe;
        box-shadow: 0px 10px 10px 0px rgba(196, 196, 196, 0.3);
        border-top: 1px solid #eeeeee;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 25px 26px;
        box-sizing: border-box;

        .card-btn {
          width: 82%;
          height: 42px;
          background: #e3e2e3;
          border-radius: 6px;
          font-size: 14px;
          color: #292c35;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .card-btns {
          cursor: pointer;
          width: 82%;
          height: 42px;
          background: #1b5ee7;
          border-radius: 6px;
          font-size: 14px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
.report-box {
  height: 412px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  .left-text {
    height: 68px;
    width: 85px;
    margin-top: 20px;
    margin-left: 27px;
  }
  .right-text {
    height: 31px;
    width: 38px;
    align-self: flex-end;
    margin-bottom: 10px;
    margin-right: 24px;
  }
  .left {
    width: 337px;
    // height: 213px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .score {
      height: 230px;
      width: 230px;
      border-radius: 50%;
      border: 6px solid #f9f9f9;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;

      .score-c {
        width: 200px;
        height: 200px;
        background: #ffffff;
        border: 6px solid #e5e5e5;
        border-radius: 50%;
        box-sizing: border-box;

        .score-txt {
          font-size: 18px;
          color: #292c35;
          display: flex;
          justify-content: center;
          margin-top: 42px;
        }

        .score-num {
          font-size: 70px;
          font-weight: 500;
          color: #ff4e0d;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          margin-top: 5px;

          span {
            font-size: 16px;
            color: #292c35;
            margin-bottom: 14px;
          }
        }
      }
    }

    .time {
      font-size: 14px;
      color: #292c35;
      margin-top: 10px;
      display: flex;
      align-items: flex-end;
      span {
        font-size: 20px;
        color: #ff4e0d;
      }
      .time-txt {
        font-size: 14px;
        color: #292c35;
        margin-top: 5px;
        margin-left: 20px;
      }
    }
  }

  .right {
    width: 500px;
    // height: 213px;
    display: flex;
    .report-msg {
      font-size: 14px;
      color: #ff4e0d;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .right-t {
      display: flex;
      align-items: center;
      margin-top: 66px;

      .item {
        font-size: 16px;
        color: #97979f;
        width: 25%;
        // display: flex;
        // justify-content: center;
      }
    }

    .right-c {
      display: flex;
      align-items: center;
      margin-top: 25px;
      width: 50%;
      justify-content: center;
      .items {
        font-size: 14px;
        color: #292c35;
      }

      .items-a {
        color: #ff4e0d;
        margin-left: 10px;
      }
    }
  }
  .report-btn {
    display: flex;
    align-items: center;
    // justify-content: center;
    margin-top: 45px;
    height: 94px;

    .btn {
      height: 40px;
      padding: 0 19px;
      box-sizing: border-box;
      background: #11aa8c;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        height: 12px;
        width: 14px;
        margin-right: 5px;
      }
    }
  }
}
</style>
