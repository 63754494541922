import request from '@/utils/request'
// 新增聊天记录
export function commitPaper(data) {
  return request({
    url: 'exam/stu/commitPaper',
    method: 'post',
    data: data,
    headers : {"authorization":localStorage.getItem('GFtoken')}
  })
}
// 开始做题
export function beginPaper(data) {
  return request({
    url: 'exam/stu/beginPaper' + '/' + data,
    method: 'get',
    headers : {"authorization":localStorage.getItem('GFtoken')}

  })
}
